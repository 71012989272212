import { makeStyles } from "@material-ui/core/styles"
import React, { FC } from "react"

import Theme from "@/entity/theme/theme"
import StickyNote from "@/presentation/atoms/stickyNote"

/**
 * 見出しコンポーネント
 * @param explanationTitle 見出しのタイトル
 * @param explanationSubTitle 見出しのサブタイトル
 * @param Element 返却されたjsonデータ
 */

const useStyles = makeStyles(() => ({
    hitRateExplantation: {
        fontFamily: Theme.font.fontFamily,
        padding: "16px 24px",
        background: "#fff",
        textAlign: "center",
        borderBottom: "1px solid #e5e5e5",
        display: "flex",
        flexDirection: "column",
        "@media screen and (min-width:1024px) ": {
            flexDirection: "row",
            textAlign: "left",
            flex: "auto",
            padding: "2% 10%",
        },
    },
    title: {
        color: Theme.color.primaryColor,
    },
    subTitle: {
        fontWeight: 400,
    },
    explanation: {
        fontWeight: 400,
    },
    explanationPic: {
        width: "100%",
        maxWidth: "650px",
    },
    textBox: {
        flex: "0 0 50%",
        "@media screen and (min-width:1024px) ": {
            paddingRight: "10px",
        },
    },
    picBox: {
        flex: "0 0 50%",
        textAlign: "center",
        "@media screen and (min-width:1024px) ": {
            paddingLeft: "10px",
        },
    },
}))

type HitRateExplantationProps = {
    title: string
    subTitle: string
    explanation: string
    explanationPic: string
    explantationIndex: number
}

const HitRateExplantation: FC<HitRateExplantationProps> = (props) => {
    const classes = useStyles()
    const { title, subTitle, explanation, explanationPic, explantationIndex } = props
    return (
        <div className={classes.hitRateExplantation}>
            <section className={classes.textBox}>
                <StickyNote>{explantationIndex}</StickyNote>
                <h2 className={classes.title}>{title}</h2>
                <h3 className={classes.subTitle}>{subTitle}</h3>
                <h4 className={classes.explanation}>{explanation}</h4>
            </section>
            <section className={classes.picBox}>
                <img className={classes.explanationPic} src={explanationPic} />
            </section>
        </div>
    )
}

export default HitRateExplantation
