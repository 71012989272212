import { makeStyles } from "@material-ui/core/styles"
import React, { FC } from "react"

import Theme from "@/entity/theme/theme"

const useStyles = makeStyles(() => ({
    stickyNote: {
        background: Theme.color.primaryColor,
        display: "inline-block",
        padding: "40px 12px 8px",
        marginTop: 0,
        color: "#fff",
    },
}))

type StickyNoteProps = {
    children: number
}

const StickyNote: FC<StickyNoteProps> = (props) => {
    const classes = useStyles()
    return <div className={classes.stickyNote}>{props.children}</div>
}

export default StickyNote
