import { navigate } from "gatsby"
import React from "react"

import { Contacts, LoginUser, MemberData } from "@/entity/types/fetchData"
import HomeTemplate from "@/presentation/templates/homeTemplate"
import LpTemplate from "@/presentation/templates/lpTemplate"
import { userRepository } from "@/repository/action"

type MainProps = {
    location?: {
        action: string
        state: { loginUser: LoginUser; memberData: MemberData; contacts: Contacts }
    }
}

const Main: React.FC<MainProps> = (props) => {
    const [loginUser, setLoginUser] = React.useState<LoginUser>()
    const [memberData, setMemberData] = React.useState<MemberData>()
    const [contacts, setContacts] = React.useState<Contacts>()
    const [linearAnimation, setLinearAnimation] = React.useState<boolean[] | undefined>()
    const [notificationCount, setNotificationCount] = React.useState(0)

    const [isSession, setIsSession] = React.useState(false)

    React.useEffect(() => {
        if (props.location?.action) {
            if (props.location.state.loginUser) {
                setIsSession(true)
                setLoginUser(props.location.state.loginUser)
            }
            if (props.location.state.memberData) {
                setMemberData(props.location.state.memberData)
            }
            if (props.location.state.contacts) {
                setContacts(props.location.state.contacts)
                setLinearAnimation(Array(props.location.state.contacts.length).fill(false))
            }
        } else {
            let getUserjson = localStorage.getItem("loginUser")
            if (getUserjson) {
                setIsSession(true)
                userRepository
                    .sessionLogin(JSON.parse(getUserjson).email, JSON.parse(getUserjson).password)
                    .then((data) => {
                        setLoginUser(data.user_info)
                        setMemberData(data.memberData)
                        setContacts(data.contact_array.reverse())
                        setLinearAnimation(Array(data.length).fill(false))
                    })
                    .catch(() => {
                        localStorage.clear()
                        navigate("/")
                    })
            } else {
                navigate("/")
                setIsSession(false)
            }
        }
    }, [])

    React.useEffect(() => {
        if (memberData) {
            let notification_count = 0
            memberData.forEach((v) => {
                if (v.permission == "1") {
                    notification_count++
                }
            })
            setNotificationCount(notification_count)
        }
    }, [memberData])

    return (
        <>
            {isSession ? (
                <HomeTemplate
                    loginUser={loginUser}
                    setLoginUser={setLoginUser}
                    memberData={memberData}
                    setMemberData={setMemberData}
                    contacts={contacts}
                    setContacts={setContacts}
                    notificationCount={notificationCount}
                    linearAnimation={linearAnimation}
                    setLinearAnimation={setLinearAnimation}
                />
            ) : (
                <LpTemplate />
            )}
        </>
    )
}

export default Main
