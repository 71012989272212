import { makeStyles } from "@material-ui/core/styles"
import moment from "moment"
import React from "react"
import styled from "styled-components"

import Theme from "../../entity/theme/theme"
import BoxLoading from "../atoms/boxLoading"
import ContactCard from "../organisms/home/contactCard"

import FrameTemplate from "@/presentation/templates/frameTemplate"
const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: "#fff",
        fontFamily: Theme.font.fontFamily,
        overflowY: "auto",
        overflowX: "hidden",
    },
    style: {
        width: "100%",
        height: "100%",
        margin: "auto",
        background: "#fff",
        textAlign: "center",
    },
    margin: {
        margin: "25% 0%",
    },

    buttonPosition: {
        position: "fixed",
        bottom: 0,
        right: "3%",
    },
}))

const HomeTemplate = (props) => {
    const classes = useStyles()
    const {
        loginUser,
        setLoginUser,
        memberData,
        setMemberData,
        contacts,
        setContacts,
        notificationCount,
        linearAnimation,
        setLinearAnimation,
    } = props
    const [rangeDate, setRangeDate] = React.useState({
        startDate: moment().startOf("month"),
        endDate: moment().endOf("month"),
    })
    const [title, setTitle] = React.useState("連絡")
    const [isLoading, setIsLoading] = React.useState(false) //ローディングのflag

    return (
        <div className={classes.root}>
            <FrameTemplate
                loginUser={loginUser}
                setLoginUser={setLoginUser}
                setIsLoading={setIsLoading}
                rangeDate={rangeDate}
                setRangeDate={setRangeDate}
                memberData={memberData}
                setMemberData={setMemberData}
                setTitle={setTitle}
                title={title}
                notificationCount={notificationCount}
                setContacts={setContacts}
            />
            <Contents>
                {!loginUser || !memberData || isLoading ? (
                    <BoxLoading />
                ) : (
                    <div style={{ background: "#fff", padding: "52px 0px 100px 0px" }}>
                        <div className={classes.style}>
                            {contacts && linearAnimation && (
                                <>
                                    {contacts.length > 0 &&
                                        contacts.map((value, index, array) => {
                                            return (
                                                <>
                                                    <ContactCard
                                                        value={value}
                                                        loginUser={loginUser}
                                                        setContacts={setContacts}
                                                        index={index}
                                                        contacts={contacts}
                                                        linearAnimation={linearAnimation[index]}
                                                        setLinearAnimation={setLinearAnimation}
                                                        contactsNum={array.length}
                                                    />
                                                    {array.length == index + 1 && (
                                                        <div
                                                            style={{
                                                                paddingBottom: "90px",
                                                            }}
                                                        />
                                                    )}
                                                </>
                                            )
                                        })}
                                </>
                            )}
                        </div>
                    </div>
                )}
            </Contents>
        </div>
    )
}

const Contents = styled.div`
    height: 100vh;
    background: #fff;
`

export default HomeTemplate
