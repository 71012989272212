import IconButton from "@material-ui/core/IconButton"
import { makeStyles, Theme } from "@material-ui/core/styles"
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import React, { FC } from "react"

const useStyles = makeStyles<Theme, UpScrollButtonProps>((theme) => ({
    root: {
        position: "absolute",
        zIndex: 10000000,
        right: (props) => props.right,
        bottom: (props) => props.bottom,
        background: "#2b2b2b",
        borderRadius: "50%",
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    iconColor: {
        color: "#fff",
    },
}))

type UpScrollButtonProps = {
    right: string
    bottom: string
    scrollFunc: () => void
}

/**
 * 上にスクロールさせるボタン
 * @param right 右からpx
 * @param bottom 下からpx
 * @param scrollFunc スクロールさせる関数
 */

const UpScrollButton: FC<UpScrollButtonProps> = (props) => {
    const classes = useStyles(props)
    const { scrollFunc } = props

    return (
        <div className={classes.root}>
            <IconButton aria-label="スクロール上昇" size="small" onClick={scrollFunc}>
                <ArrowUpwardIcon className={classes.iconColor} />
            </IconButton>
        </div>
    )
}

export default UpScrollButton
