import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import { navigate } from "gatsby"
import React from "react"
// import { TwitterTimelineEmbed } from "react-twitter-embed"

import { hitRateExplantationText, organization, useText } from "@/entity/mock/lpText"
import Theme from "@/entity/theme/theme"
import LpMainPic from "@/images/lpPic/lpMain.png"
import HitRatePic from "@/images/mainPic/signInIcon.png"
import Headeing from "@/presentation/atoms/heading"
import UpScrollButton from "@/presentation/atoms/upScrollButton"
import VerticalLine from "@/presentation/atoms/verticalLine"
import HitRateExplantation from "@/presentation/organisms/lp/hitRateExplanation"

const useStyles = makeStyles(() => ({
    appBar: {
        fontFamily: Theme.font.fontFamily,
        backgroundColor: Theme.color.primaryColor,
        display: "flex",
        flexDirection: "row",
        height: "56px",
    },
    title: {
        paddingLeft: "4px",
        textTransform: "capitalize",
        fontSize: "17px",
    },
    titleArea: {
        color: "#fff",
        fontFamily: Theme.font.fontFamily,
        display: "flex",
        alignItems: "center",
        marginLeft: "10px",
    },
    actionArea: {
        display: "flex",
        alignItems: "center",
        margin: "0px 20px 0px auto",
    },
    signInButton: {
        fontFamily: Theme.font.fontFamily,
        textTransform: "capitalize",
        fontSize: "14px",
        border: "#fff 1px solid",
    },
    hitRatePic: {
        height: "45px",
        width: "45px",
    },
    mainContent: {
        color: "#fff",
        fontFamily: Theme.font.fontFamily,
        backgroundColor: Theme.color.primaryColor,
        marginTop: "56px",
        height: "440px",
        position: "relative",
        textAlign: "center",
        padding: "50px 36px",
        "@media screen and (min-width:1024px) ": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    },
    lpMainPic: {
        padding: "0px 40px",
        width: "80%",
        maxWidth: "350px",
        "@media screen and (min-width:1024px) ": {
            width: "100%",
            maxWidth: "450px",
        },
    },
    lpMainTitle: {
        fontSize: "45px",
    },
    lpMainSubTitle: {
        fontSize: "18px",
        fontWeight: 400,
    },

    use: {
        fontFamily: Theme.font.fontFamily,
        padding: "16px 24px",
        background: "#fff",
        textAlign: "center",
        borderBottom: "1px solid #e5e5e5",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        "@media screen and (min-width:1024px) ": {
            flexDirection: "row",
            flex: "auto",
            padding: "2% 2%",
        },
    },
    useTextBox: {
        flex: "0 0 50%",
    },
    useTitle: {
        textAlign: "center",
        color: Theme.color.primaryColor,
    },
    useSection: {
        padding: "14px 16px",
        "@media screen and (min-width:1024px) ": {
            padding: "0px 90px",
        },
    },
    useSubTitle: {
        textAlign: "center",
        fontWeight: 400,
    },
    verticalLine: {
        position: "absolute",
        top: "0px",
        left: "50%",
        "@media screen and (max-width:1024px) ": {
            opacity: 0,
        },
    },
    organization: {
        fontFamily: Theme.font.fontFamily,
        padding: "16px 24px",
        background: "#fff",
        borderBottom: "1px solid #e5e5e5",
        display: "flex",
        justifyContent: "center",
        "@media screen and (min-width:1024px) ": {
            flex: "auto",
            padding: "2% 2%",
        },
    },
    organizationDl: {
        display: "flex",
        borderBottom: "1px solid #e5e5e5",
        margin: 0,
    },
    organizationSection: {
        width: "95%",
    },
    introduction: {
        fontFamily: Theme.font.fontFamily,
        background: "#fff",
        textAlign: "center",
        display: "flex",
        padding: "16px 24px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "@media screen and (min-width:1024px) ": {
            flex: "auto",
            padding: "2% 2%",
        },
    },
    introductionQr: {
        marginTop: "8px",
    },
    notice: {
        fontFamily: Theme.font.fontFamily,
        background: Theme.color.primaryColor,
        color: "#fff",
        display: "flex",
        padding: "16px 24px",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        "@media screen and (min-width:1024px) ": {
            flex: "auto",
            padding: "2% 2%",
        },
    },
    footer: {
        color: "#fff",
        textAlign: "center",
        fontSize: "14px",
        background: "#010A17",
        padding: "30px 0",
        position: "relative",
    },
}))

const LpTemplate: React.FC = () => {
    const classes = useStyles()

    const ref = React.useRef<HTMLDivElement>(null)

    const upScroll = React.useCallback(() => {
        ref?.current?.scrollIntoView({
            behavior: "smooth",
        })
    }, [ref])

    const toSignIn = () => {
        navigate("/signIn")
    }

    const toMain = () => {
        navigate("/")
    }

    return (
        <div ref={ref}>
            <AppBar className={classes.appBar}>
                <Button className={classes.titleArea} onClick={toMain}>
                    <img className={classes.hitRatePic} src={HitRatePic} />
                    <span className={classes.title}>HitRate</span>
                </Button>
                <div className={classes.actionArea}>
                    <Button color="inherit" className={classes.signInButton} onClick={toSignIn}>
                        Sign In / SignUp
                    </Button>
                    {/* <IconButton color="inherit" aria-label="menu">
                        <MenuIcon />
                    </IconButton> */}
                </div>
            </AppBar>
            <div className={classes.mainContent}>
                <section>
                    <h1 className={classes.lpMainTitle}>HitRate</h1>
                    <h3 className={classes.lpMainSubTitle}>
                        部員の的中率や出欠をオンライン上で管理できます。
                    </h3>
                </section>
                <img className={classes.lpMainPic} src={LpMainPic} />
            </div>
            <Headeing explanationTitle="HitRateとは?" />
            {hitRateExplantationText.map((value, index) => {
                return (
                    <HitRateExplantation
                        key={"hitRateExplantation_" + index}
                        explantationIndex={index + 1}
                        title={value.title}
                        subTitle={value.subTitle}
                        explanation={value.explanation}
                        explanationPic={value.explanationPic}
                    />
                )
            })}
            <Headeing explanationTitle="利用にあたって" />
            <div className={classes.use}>
                {useText.map((value, index) => {
                    return (
                        <div key={"use_" + index} className={classes.useTextBox}>
                            <section className={classes.useSection}>
                                <h2 className={classes.useTitle}>{value.title}</h2>
                                <h3 className={classes.useSubTitle}>{value.subTitle}</h3>
                            </section>
                            {index == 0 && (
                                <div className={classes.verticalLine}>
                                    <VerticalLine />
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>
            <Headeing explanationTitle="導入団体" />
            <div className={classes.organization}>
                <section>
                    {organization.map((value, index) => {
                        return (
                            <dl key={"organization_" + index} className={classes.organizationDl}>
                                <dt>
                                    <p style={{ borderBottom: "1px solid" }}>{value.name}</p>
                                </dt>
                                <dd>
                                    {value.team.map((name, i) => {
                                        return <p key={"team_" + i}>{name}</p>
                                    })}
                                </dd>
                            </dl>
                        )
                    })}
                </section>
            </div>
            <Headeing explanationTitle="HitRateを導入する" />
            <section className={classes.introduction}>
                <h3>
                    こちらの公式アカウントを追加し、
                    <br />
                    導入希望のメッセージを送ってください！
                </h3>
                <img
                    src="https://qr-official.line.me/sid/M/718gwkhu.png?shortenUrl=true"
                    alt=""
                    height="200px"
                    width="200px"
                ></img>
                <a href="https://lin.ee/Qeuyfo0">
                    <img
                        src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                        alt="友だち追加"
                        height="36"
                    />
                </a>
                <h4>
                    LINEを使用していない方は、こちらから
                    <br />
                    メールアドレス：atoko0210@gmail.com
                </h4>
            </section>
            <Headeing explanationTitle="お知らせ" />
            <section className={classes.notice}>
                <h3>お知らせやアップデート内容は、Twitterにてお知らせいたします。</h3>
                {/* <TwitterTimelineEmbed
                    onLoad={function noRefCheck() {}}
                    options={{
                        height: 600,
                    }}
                    sourceType="profile"
                    userId={"1256961021597544454"}
                /> */}
                <a
                    href="https://twitter.com/atoko_hitrate?ref_src=twsrc%5Etfw"
                    className="twitter-follow-button"
                    data-show-count="false"
                >
                    Follow @atoko_hitrate
                </a>
                <script
                    async
                    src="https://platform.twitter.com/widgets.js"
                    charSet="utf-8"
                ></script>
            </section>
            <footer className={classes.footer}>
                <UpScrollButton right={"8px"} bottom={"56px"} scrollFunc={upScroll} />© Copyright
                2022 HitRate. All rights reserved.
            </footer>
        </div>
    )
}

export default LpTemplate
