import { makeStyles } from "@material-ui/core/styles"
import React, { FC } from "react"

import Theme from "@/entity/theme/theme"

/**
 * 見出しコンポーネント
 * @param explanationTitle 見出しのタイトル
 * @param explanationSubTitle 見出しのサブタイトル
 * @param Element 返却されたjsonデータ
 */

const useStyles = makeStyles(() => ({
    explanation: {
        fontFamily: Theme.font.fontFamily,
        position: "relative",
        padding: "4px 24px",
        background: "#3B4552",
        textAlign: "center",
        color: "#fff",
    },
}))

type HeadingProps = {
    explanationTitle: string
}

const Headeing: FC<HeadingProps> = (props) => {
    const classes = useStyles()
    const { explanationTitle } = props
    return (
        <header className={classes.explanation}>
            <h2>{explanationTitle}</h2>
        </header>
    )
}

export default Headeing
