import AttendancePic from "@/images/lpPic/attendance.png"
import ContactPic from "@/images/lpPic/contact.png"
import MemberRecordsPic from "@/images/lpPic/memberRecords.png"
import MemberRecordsListPic from "@/images/lpPic/memberRecordsList.png"
import RankPic from "@/images/lpPic/rank.png"
import RecordPic from "@/images/lpPic/record.png"

export const hitRateExplantationText = [
    {
        title: "部員の的中率管理",
        subTitle: "リストで部員の記録を表示",
        explanation:
            "通常練習、強化練習、自主練習ごとに分けて、月ごとや週ごとに的中を見ることが可能。的中率や総本数などでソートも。",
        explanationPic: MemberRecordsListPic,
    },
    {
        title: "部員の的中率分析",
        subTitle: "グラフとカレンダーで見やすく",
        explanation:
            "記録は、カレンダーとグラフで表示し、分析しやすく。的中グラフから調子の流れを確認。",
        explanationPic: MemberRecordsPic,
    },
    {
        title: "部員の出欠管理",
        subTitle: "早退者、遅刻者、欠席者を一覧に",
        explanation:
            "出欠連絡をアプリに投稿。連絡は、幹部部員のメールアドレスにも通知され、アプリ上で部員の出欠を一覧表示。",
        explanationPic: AttendancePic,
    },
    {
        title: "部内で連絡",
        subTitle: "他のチャットツールの見逃し予防に",
        explanation:
            "部員全員に連絡し、予定勘違いの予防に。現役生のメールアドレスに送信することも可能。",
        explanationPic: ContactPic,
    },
    {
        title: "個人の的中率管理",
        subTitle: "個人の成長に",
        explanation:
            "記録は、個人でも管理可能。カレンダー、グラフ、表、AIによるコメントで的中率向上のサポート。総本数、的中数のみの簡単記録。",
        explanationPic: RecordPic,
    },
    {
        title: "ランクやバッジ",
        subTitle: "部内の順位や月のベスト記録も表示",
        explanation:
            "各プロフィールには、部内の順位表示（5位まで）、ランクやバッジ解放などのゲーム的要素も。",
        explanationPic: RankPic,
    },
]

export const useText = [
    {
        title: "無料です",
        subTitle:
            "HitRateは無料で使用することができます。サーバー代などをまかなうため、一部広告がつく可能性があります。",
    },
    {
        title: "団体利用です",
        subTitle:
            "HitRateはシステム上部員全員が利用する必要があります。一人一人がHitRateをダウンロードし、的中を個人でつけていただくようになります。",
    },
]

export const organization = [
    {
        name: "大学",
        team: ["徳島大学", "香川大学", "高知大学(女子)", "大道大学", "上智大学"],
    },
    {
        name: "高校",
        team: [
            "栃木県私立足利大学附属高等学校",
            "茨城県下妻第二高校",
            "兵庫県立姫路飾西高等学校",
            "鹿児島実業高校",
        ],
    },
]
