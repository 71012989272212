import AppBar from "@material-ui/core/AppBar"
import Avatar from "@material-ui/core/Avatar"
import Dialog from "@material-ui/core/Dialog"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Slide from "@material-ui/core/Slide"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import React from "react"

import Theme from "../../../entity/theme/theme"

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
        backgroundColor: Theme.color.primaryColor,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        fontFamily: Theme.font.fontFamily,
    },
    listItemText: {
        fontFamily: Theme.font.fontFamily,
        fontWeight: "500",
        margin: "auto 3px",
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function ResponseListDialog(props) {
    const { contact, responseListOpen, setResponseListOpen } = props
    const classes = useStyles()

    const handleClose = () => {
        setResponseListOpen(false)
    }

    return (
        <div>
            {contact && (
                <Dialog
                    fullScreen
                    open={responseListOpen}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                了解リスト
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <List>
                        {contact.response.map((value) => {
                            return (
                                <>
                                    <ListItem>
                                        <Avatar
                                            style={{
                                                width: "33px",
                                                height: "33px",
                                                fontSize: "1em",
                                            }}
                                        >
                                            {value.slice(0, 1)}
                                        </Avatar>{" "}
                                        <ListItemText
                                            primary={value}
                                            className={classes.listItemText}
                                        />
                                    </ListItem>
                                    <Divider />
                                </>
                            )
                        })}
                    </List>
                </Dialog>
            )}
        </div>
    )
}
