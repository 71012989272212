import { makeStyles } from "@material-ui/core/styles"
import React, { FC } from "react"

const useStyles = makeStyles(() => ({
    verticalLine: {
        width: "1px",
        height: "300px",
        background: "grey",
    },
}))

const VerticalLine: FC = () => {
    const classes = useStyles()
    return <div className={classes.verticalLine} />
}

export default VerticalLine
