import Avatar from "@material-ui/core/Avatar"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Dialog from "@material-ui/core/Dialog"
import IconButton from "@material-ui/core/IconButton"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import DeleteIcon from "@material-ui/icons/Delete"
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople"
import { navigate } from "gatsby"
import React from "react"
import Draggable from "react-draggable"
import fontStyle from "src/entity/theme/fontStyle"
import swal from "sweetalert"

import swalErrorAction from "../../../entity/alert/swalErrorAction"
import { willDelete, networkError, deleteError } from "../../../entity/alert/swalErrorList"
import Theme from "../../../entity/theme/theme"
import { storage } from "../../../infra/firebase"
import { contactRepository } from "../../../repository/action"
import LinearProgress from "../../atoms/linearProgress"

import ResponseListDialog from "./responseListDialog"

const useStyles = makeStyles({
    card: {
        minWidth: 275,
        backgroundColor: "white",
        margin: "3%",
        position: "relative",
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    media: {
        height: 100,
    },
    title: {
        fontSize: "1.5em",
        fontWeight: "bold",
        textAlign: "left",
        fontFamily: Theme.font.fontFamily,
    },
    item: {
        color: "#fff",
        fontWeight: "bold",
        position: "absolute",
        right: "0px",
        fontSize: "16px",
        borderBottomLeftRadius: "4px",
        padding: "2px",
    },
    description: {
        borderRadius: "10px",
        marginRight: "10px",
        width: "90%",
        height: "100%",
        display: "inline-block",
        whiteSpace: "pre-line",
        textAlign: "left",
        fontFamily: Theme.font.fontFamily,
        fontWeight: "500",
    },
    contributor: {
        textAlign: "left",
        fontSize: "15px",
        display: "flex",
        marginBottom: "-15px",
        fontFamily: Theme.font.fontFamily,
        fontWeight: "600",
    },
    imageSize: {
        width: "100%",
        height: "auto",
        maxWidth: "140px",
        //maxHeight: "140px",
        "@media screen and (min-width:600px) ": {
            maxWidth: "1000px",
            maxHeight: "1000px",
        },
    },
    root: {
        //marginRight: "0px",
        paddingRight: "9px",
        fontSize: "11px",
    },
})

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    )
}

export default function ContactCard(props) {
    const classes = useStyles()
    const {
        value,
        loginUser,
        setContacts,
        index,
        linearAnimation,
        setLinearAnimation,
        contactsNum,
    } = props
    const [deleteButtonFlag, setDeleteButtonFlag] = React.useState(false)
    const [responseListOpen, setResponseListOpen] = React.useState(false)
    const [expand, setExpand] = React.useState(false)
    const [picture, setPicture] = React.useState()

    const handleClickOpen = () => {
        setResponseListOpen(true)
    }

    function AutoLink(str) {
        var regexp_url = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g // ']))/;
        var regexp_makeLink = function (all, url, h, href) {
            return '<a href="h' + href + '">' + url + "</a>"
        }

        return str.replace(regexp_url, regexp_makeLink)
    }
    return (
        <Card className={classes.card}>
            {linearAnimation && <LinearProgress />}
            {loginUser && value && (
                <>
                    <Typography
                        variant="subtitle2"
                        className={classes.item}
                        style={{
                            backgroundColor: `${value.itemColor}`,
                        }}
                        color="textSecondary"
                    >
                        <span style={fontStyle("bold")}>{value.item}</span>
                    </Typography>

                    <div style={{ margin: "11px" }}>
                        <Typography variant="overline" className={classes.contributor}>
                            {
                                <>
                                    <div style={{ margin: "auto 4px" }}>
                                        {value.contributor_id == "000000000" ? (
                                            <Avatar
                                                style={{ background: Theme.color.primaryColor }}
                                            >
                                                {value.contributor.slice(0, 1)}
                                            </Avatar>
                                        ) : (
                                            <>
                                                {value.contributor_gender == "woman" ? (
                                                    <Avatar
                                                        style={{
                                                            background: Theme.color.womanIconColor,
                                                        }}
                                                    >
                                                        {value.contributor.slice(0, 1)}
                                                    </Avatar>
                                                ) : (
                                                    <Avatar
                                                        style={{
                                                            background: Theme.color.manIconColor,
                                                        }}
                                                    >
                                                        {value.contributor.slice(0, 1)}
                                                    </Avatar>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div style={{ fontSize: "18px", display: "inline-flex" }}>
                                            {value.contributor}
                                        </div>
                                        {value.contributor_id == "000000000" ? (
                                            <div
                                                style={{
                                                    fontSize: "12px",
                                                    display: "inline-flex",
                                                    marginTop: "-18px",
                                                }}
                                            >
                                                {value.post_date}
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                    fontSize: "12px",
                                                    display: "inline-flex",
                                                    marginTop: "-18px",
                                                }}
                                            >
                                                {value.post_date.split("年")[1]}
                                            </div>
                                        )}
                                    </div>
                                </>
                            }
                        </Typography>

                        <CardContent>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Typography
                                    className={classes.title}
                                    variant="subtitle1"
                                    component="h2"
                                >
                                    {value.title}
                                </Typography>
                                <Typography className={classes.description}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: AutoLink(value.description),
                                        }}
                                    ></div>
                                </Typography>

                                <div
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        flexDirection: "row",
                                    }}
                                >
                                    {value.image_url &&
                                        value.image_url.map((value, i) => {
                                            return (
                                                <div
                                                    style={{ width: "140px", height: "auto" }}
                                                    key={"image_url" + i}
                                                >
                                                    <img
                                                        src={value}
                                                        className={classes.imageSize}
                                                        onClick={() => {
                                                            setExpand(true)
                                                            setPicture(value)
                                                        }}
                                                    />
                                                </div>
                                            )
                                        })}
                                </div>
                                <Dialog
                                    onClose={() => {
                                        setExpand(false)
                                        setPicture(false)
                                    }}
                                    open={expand}
                                    PaperComponent={PaperComponent}
                                    aria-labelledby="draggable-dialog-title"
                                    //fullScreen
                                    style={{ overflow: "hidden" }}
                                >
                                    <div style={{ display: "flex", marginLeft: "auto" }}>
                                        <IconButton
                                            color="secondary"
                                            onClick={() => {
                                                setExpand(false)
                                                setPicture(false)
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                    <div
                                        style={{ width: "100%", height: "auto", overflow: "auto" }}
                                    >
                                        <img
                                            src={picture}
                                            style={{ width: "100%", height: "auto" }}
                                        />
                                    </div>
                                </Dialog>
                            </div>
                        </CardContent>

                        {/* </CardActionArea> */}
                        {/* 運営連絡のコンタクトIDは0000000 */}
                        <div style={{ display: "flex" }}>
                            {loginUser && value.contact_id != "0000000" && (
                                <>
                                    <div>
                                        <IconButton
                                            aria-label="watch"
                                            disabled={value.response.some((v) => {
                                                return loginUser.name == v
                                            })}
                                            className={classes.root}
                                            onClick={() => {
                                                contactRepository
                                                    .contactResponse(loginUser, value.contact_id)
                                                    .then(() => {
                                                        contactRepository
                                                            .contactsFetch(loginUser)
                                                            .then((data) => {
                                                                setContacts(data.reverse())
                                                            })
                                                            .catch(() => {
                                                                swalErrorAction(networkError)
                                                            })
                                                    })
                                                    .catch(() => {
                                                        swalErrorAction(networkError)
                                                    })
                                            }}
                                        >
                                            {value.response.some((v) => {
                                                return loginUser.name == v
                                            }) ? (
                                                <>
                                                    <span style={fontStyle("400")}>
                                                        了解しました
                                                    </span>{" "}
                                                    <EmojiPeopleIcon style={{ color: "#4caf50" }} />
                                                </>
                                            ) : (
                                                <>
                                                    <span style={fontStyle("400")}>
                                                        了解しました
                                                    </span>{" "}
                                                    <EmojiPeopleIcon />
                                                </>
                                            )}
                                        </IconButton>
                                    </div>
                                    {/* <div style={{ marginLeft: "auto" }}> */}
                                    <IconButton
                                        aria-label="watch"
                                        className={classes.root}
                                        onClick={handleClickOpen}
                                    >
                                        <EmojiPeopleIcon />
                                        {value.response.length}
                                    </IconButton>
                                    {/* </div> */}
                                    <div style={{ marginLeft: "auto" }}>
                                        {loginUser && value && loginUser.name == value.contributor && (
                                            <IconButton
                                                disabled={deleteButtonFlag}
                                                aria-label="delete"
                                                className={classes.root}
                                                color="secondary"
                                                style={{ marginTop: "auto", marginLeft: "auto" }}
                                                onClick={() => {
                                                    swal(willDelete).then((val) => {
                                                        if (val) {
                                                            setDeleteButtonFlag(true)
                                                            let contactsFlag =
                                                                Array(contactsNum).fill(false)
                                                            contactsFlag[index] = true
                                                            setLinearAnimation(contactsFlag)
                                                            value.image_url.forEach((v) => {
                                                                let fileName = v
                                                                    .split("%2F")[1]
                                                                    .split("?")[0]
                                                                fileName =
                                                                    decodeURIComponent(fileName)
                                                                let desertRef = storage.ref(
                                                                    `/${
                                                                        loginUser.id.slice(0, 5) +
                                                                        "_images"
                                                                    }/${fileName}`
                                                                )
                                                                desertRef.delete().then(() => {})
                                                            })
                                                            contactRepository
                                                                .contactDelete(
                                                                    loginUser,
                                                                    value.contact_id
                                                                )
                                                                .then(() => {
                                                                    return (
                                                                        swal({
                                                                            title: "投稿を削除しました",
                                                                            icon: "success",
                                                                            buttons: false,
                                                                            timer: 1500,
                                                                        }),
                                                                        setLinearAnimation(
                                                                            Array(contactsNum).fill(
                                                                                false
                                                                            )
                                                                        ),
                                                                        contactRepository
                                                                            .contactsFetch(
                                                                                loginUser
                                                                            )
                                                                            .then((data) => {
                                                                                setContacts(
                                                                                    data.reverse()
                                                                                )
                                                                                setDeleteButtonFlag(
                                                                                    false
                                                                                )
                                                                            })
                                                                            .catch(() => {
                                                                                swal(
                                                                                    deleteError
                                                                                ).then(() => {
                                                                                    localStorage.clear()
                                                                                    navigate("/")
                                                                                })
                                                                            })
                                                                    )
                                                                })
                                                                .catch(() => {
                                                                    swalErrorAction(networkError)
                                                                })
                                                        }
                                                    })
                                                }}
                                            >
                                                <DeleteIcon />
                                                削除
                                            </IconButton>
                                        )}
                                    </div>
                                    <ResponseListDialog
                                        contact={value}
                                        responseListOpen={responseListOpen}
                                        setResponseListOpen={setResponseListOpen}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
        </Card>
    )
}
